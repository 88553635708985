<template>
  <b-container>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
              <b-form  @submit.prevent="handleSubmit(register)" >
                  <b-row>
                    <b-col lg="5" sm="12">
                      <ValidationProvider name="Organization" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="org_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="stockOut.org_id"
                            :options="orgList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="5" sm="12">
                      <ValidationProvider name="Stock Date" vid="stock_out_date" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="stock_out_date"
                          slot-scope="{ valid, errors }"
                        >
                         <template v-slot:label>
                            {{ $t('pump_install.stock_out') + ' ' + $t('globalTrans.date') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            class="date-picker"
                            id="stock_out_date"
                            v-model="stockOut.stock_out_date"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <p style="font-weight:bold; color:gray; text-transform:uppercase">{{ $t('globalTrans.select')+' '+$t('irrigation_config.item') }}</p>
                  <hr style="margin-top:-15px">
                <div>
                  <b-row v-for="(item,index) in stockOut.items" :key="index">
                    <b-col lg="2" sm="4">
                      <ValidationProvider name="Category" vid="category_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="category_id"
                            slot-scope="{ valid, errors }"
                        >
                         <template v-slot:label>
                            {{ $t('irrigation_config.category') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              id="category_id"
                              v-model="item.category_id"
                              :options="categoryList"
                              @change="getItemList(index)"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="2" sm="4">
                      <ValidationProvider name="Item" vid="item_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="item_id"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irrigation_config.item') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              id="item_id"
                              v-model="item.item_id"
                              :options="item.itemsList"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="2" sm="4">
                      <ValidationProvider name="Unit" vid="unit_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="unit_id"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irrigation_config.unit') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              id="unit_id"
                              v-model="item.unit_id"
                              :options="unitList"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="2" sm="4">
                      <ValidationProvider name="Quantity" vid="quantity" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="12 p-0 m-0"
                          label-for="quantity"
                          slot-scope="{ valid, errors }"
                        >
                         <template v-slot:label>
                            {{ $t('pump_install.quantity') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            type="number"
                            id="quantity"
                            v-model="item.quantity"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                      <b-col lg="1" sm="4">
                        <b-form-group>
                        <br>
                        <b-button class="btn btn-outline-danger mt-3" v-if="index !== 0" variant=" iq-bg-danger" size="sm" @click.prevent="removeItem(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                        <b-button class="btn btn-outline-success mt-3" v-if="index === 0" variant=" iq-bg-success" size="sm" @click="addMore"><i class="ri-add-line m-0"></i></b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div>
                  <b-row>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Reason (En)" vid="reason">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          :label="$t('pump_install.reason')"
                          label-for="reason"
                          slot-scope="{ valid, errors }"
                        >
                          <b-form-textarea
                            id="reason"
                            style="height:70px"
                            v-model="stockOut.reason"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Reason (Bn)" vid="reason_bn">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          :label="$t('pump_install.reason_bn')"
                          label-for="reason_bn"
                          slot-scope="{ valid, errors }"
                        >
                          <b-form-textarea
                            id="reason_bn"
                            style="height:70px"
                            v-model="stockOut.reason_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Purpose (En)" vid="purpose">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          :label="$t('pump_install.purpose')"
                          label-for="purpose"
                          slot-scope="{ valid, errors }"
                        >
                          <b-form-textarea
                            id="purpose"
                            style="height:70px"
                            v-model="stockOut.purpose"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Purpose (Bn)" vid="purpose">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          :label="$t('pump_install.purpose_bn')"
                          label-for="purpose_bn"
                          slot-scope="{ valid, errors }"
                        >
                          <b-form-textarea
                            id="purpose_bn"
                            style="height:70px"
                            v-model="stockOut.purpose_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Remarks (En)" vid="remarks">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          :label="$t('pump_install.remarks')"
                          label-for="remarks"
                          slot-scope="{ valid, errors }"
                        >
                          <b-form-textarea
                            id="remarks"
                            style="height:70px"
                            v-model="stockOut.remarks"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Remarks (Bn)" vid="remarks_bn">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          :label="$t('pump_install.remarks_bn')"
                          label-for="remarks_bn"
                          slot-scope="{ valid, errors }"
                        >
                          <b-form-textarea
                            id="remarks_bn"
                            style="height:70px"
                            v-model="stockOut.remarks_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </div>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { stockOutStore } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getStockData()
      Object.freeze(tmp)
      this.stockOut = tmp
    }
  },
  mounted () {
    flatpickr('.date-picker', { defaultDate: 'today' })
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      stockOut: {
        org_id: '0',
        stock_out_date: '',
        reason: '',
        reason_bn: '',
        purpose: '',
        purpose_bn: '',
        remarks: '',
        remarks_bn: '',
        items: [
          {
            category_id: 0,
            item_id: 0,
            unit_id: 0,
            quantity: '',
            itemsList: []
          }
        ]
      },
      categoryList: [],
      isValid: false
    }
  },
  computed: {
    office: function () {
     const officeId = this.$store.state.Auth.authUser.office_id
     return this.$store.state.commonObj.officeList.find(item => item.value === officeId)
    },
    unitList: function () {
     return this.$store.state.IrriConfig.commonObj.unitList.filter(item => item.status === 0)
    },
    orgList: function () {
     return this.$store.state.commonObj.organizationProfileList
    }
  },
  watch: {
    'stockOut.org_id': function (newVal, oldVal) {
      if (newVal) {
        this.categotyList(newVal)
        this.setEmptyVal()
      }
    }
  },
  methods: {
    getStockData () {
      return this.$store.state.IrriPumpInstall.stockOutList.find(item => item.id === this.id)
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const officeData = {
        office_id: this.office.value
      }
      const finalData = Object.assign(this.stockOut, officeData)
      const loadinState = { loading: false, listReload: true }
      result = await RestApi.postData(irriSchemeServiceBaseUrl, stockOutStore, finalData)
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    removeItem (index) {
      this.stockOut.items.splice(index, 1)
    },
    addMore (index) {
      const tempItem = {
          category_id: 0,
          item_id: 0,
          unit_id: 0,
          quantity: '',
          itemsList: []
        }
      const key1 = parseInt(this.stockOut.items.length - 1)
      const item = this.stockOut.items[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
        if (key !== 'itemList') {
          if (item[key] === '' || item[key] === 0) {
            isEmpty = false
          }
        }
      })
      if (isEmpty === true) {
        this.stockOut.items.push(tempItem)
      }
    },
    categotyList (orgId) {
      const categoryList = this.$store.state.IrriConfig.commonObj.categoryList.filter(item => item.status === 0)
      this.categoryList = categoryList.filter(category => category.org_id === orgId)
    },
    getItemList (index) {
      const stock = this.stockOut.items[index]
      const orgId = this.stockOut.org_id
      const categoryId = stock.category_id

      const itemList = this.$store.state.IrriConfig.commonObj.itemList
      const itemData = itemList.filter(
        item => item.org_id === orgId &&
        item.category_id === categoryId
      )
      this.stockOut.items[index].itemsList = itemData
      const tmp = this.stockOut.items
      this.stockOut.items = []
      this.stockOut.items = tmp
    },
    setEmptyVal () {
      this.stockOut.items.map(item => {
        item.category_id = 0
        item.item_id = 0
        item.unit_id = 0
        item.quantity = ''
        item.itemsList = []
      })
    }
  }
}
</script>
<style>
  #affidavit , #affidavit_bn {
    height: 150px
  }
</style>
