<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-row>
                <b-col lg="12" sm="12" class="text-center mb-3">
                  <h5 class='complain-title'>{{ $t('pump_install.stock_out_details') }}</h5>
                </b-col>
              </b-row>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <b-table-simple striped bordered small>
                          <b-tr>
                            <b-td>{{ $t('globalTrans.organization') }}</b-td>
                            <b-td colspan="3">{{ (this.$i18n.locale=='bn') ? stockOut.org_name_bn : stockOut.org_name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ $t('pump_install.stock_out') }} {{ $t('globalTrans.id') }}</b-td>
                            <b-td>{{ stockOut.stock_out_id }}</b-td>
                            <b-td>{{ $t('pump_install.stock_out') + ' ' + $t('globalTrans.date') }}</b-td>
                            <b-td>{{ stockOut.stock_out_date | dateFormat }}</b-td>
                          </b-tr>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                     <b-row>
                        <b-col lg="12" sm="12">
                          <div>
                            <p class="p-0 m-0" style="font-weight:bold; color:black; text-transform:uppercase">{{ $t('pump_install.item_lists') }}</p>
                            <b-table striped bordered hover small :items="itemsList" :fields="items"  aria-hidden="loading | listReload ? 'true' : null">
                              <template v-slot:cell(index)="data">
                                {{ $n(data.index + slOffset) }}
                              </template>
                              <template v-slot:cell(quantity)="data">
                                {{ $n(data.item.quantity) }}
                              </template>
                            </b-table>
                          </div>
                        </b-col>
                      </b-row>
                     <b-row>
                        <b-col lg="12" sm="12">
                            <p class="p-0 m-0" style="font-weight:bold; color:black"><u>{{ $t('pump_install.reason') }}</u> :</p>
                            <p class="text-dark">{{ (this.$i18n.locale == 'bn') ? stockOut.reason_bn : stockOut.reason }}</p>
                        </b-col>
                        <b-col lg="12" sm="12">
                            <p class="p-0 m-0" style="font-weight:bold; color:black"><u>{{ $t('pump_install.purpose') }}</u> :</p>
                            <p class="text-dark">{{ (this.$i18n.locale == 'bn') ? stockOut.purpose_bn : stockOut.purpose }}</p>
                        </b-col>
                        <b-col lg="12" sm="12">
                            <p class="p-0 m-0" style="font-weight:bold; color:black"><u>{{ $t('pump_install.remarks') }}</u> :</p>
                            <p class="text-dark">{{ (this.$i18n.locale == 'bn') ? stockOut.remarks_bn : stockOut.remarks }}</p>
                        </b-col>
                      </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { stockOutList } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    this.getItemData()
    if (this.id) {
      const tmp = this.getStockData()
      Object.freeze(tmp)
      this.stockOut = tmp
    }
  },
  data () {
    return {
      stockOut: {},
      slOffset: 1,
      itemsList: []
    }
  },
  computed: {
    items () {
       const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('irrigation_config.category'), class: 'text-left' },
          { label: this.$t('irrigation_config.item'), class: 'text-left' },
          { label: this.$t('irrigation_config.unit'), class: 'text-left' },
          { label: this.$t('pump_install.quantity'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'category_name_bn' },
          { key: 'item_name_bn' },
          { key: 'unit_name' },
          { key: 'quantity' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'category_name' },
          { key: 'item_name' },
          { key: 'unit_name' },
          { key: 'quantity' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    getStockData () {
      return this.$store.state.IrriPumpInstall.stockOutList.find(item => item.id === this.id)
    },
    async getItemData () {
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, `${stockOutList}/${this.id}`)
      if (result.success) {
        this.itemsList = this.formatList(result.data)
      }
    },
    formatList (data) {
      const orgList = this.$store.state.commonObj.organizationProfileList
      let orgProfile = {}
      const listData = data.map(item => {
        orgProfile = orgList.find(orgPro => orgPro.id === item.org_id)
        const orgName = { org_name: orgProfile.org_name, org_name_bn: orgProfile.org_name_bn }
        return Object.assign({}, item, orgName)
      })
      return listData
    }
  }
}
</script>
